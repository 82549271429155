import React from 'react'
import Hotspot from './Hotspot'
import useHotspotFilter from './../../hooks/useHotspotFilter'

const MapHotspots = (props) => {
  const { panZoom, currentData, setIsAnimating, tagState, tutorialFlag } = props

  const monthHotspots = currentData.currentMonth.hotspots.items

  const { hotspotData, inactiveTags } = useHotspotFilter(
    currentData,
    tagState,
    tutorialFlag,
    setIsAnimating,
  )

  return (
    <div>
      {hotspotData.map((hotspot, index) => {
        return (
          <Hotspot
            {...hotspot}
            tag={hotspot.tag_id}
            panZoom={panZoom}
            key={index}
            index={index}
            inactiveTags={inactiveTags}
            detailPage={true}
          />
        )
      })}
    </div>
  )
}

export default MapHotspots
