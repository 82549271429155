import React, { useState, Fragment } from 'react'
import useFetch from './hooks/useFetch'
import Intro from './pages/Intro'
import imagesLoaded from 'imagesloaded'
import { fadeIn, fadeOut } from './animations/pageAnimation'
import MediaModal from './components/MediaModal'
import MapPage from './pages/MapPage'
import useInitRoute from './hooks/useInitRoute'
import useGoogleAnalytics from './hooks/useGoogleAnalytics'

const App = () => {
  // get data
  const rootElem = document.querySelector('#root')
  const fetchData = useFetch(rootElem.getAttribute('data-api-url'))
  const [steps, setSteps] = useState(null)
  useGoogleAnalytics()

  // prep loading
  const [isLoading, setIsLoading] = useState(true)

  // slides animation
  const [introIsAnimating, setIntroIsAnimating] = useState(true)

  // for development purposes : dont wait for iamges loaded, faster page jumpss
  const dev = false

  const readyToFadeIn = () => {
    const begin = () => setIsLoading(false)
    const complete = () => {}
    fadeIn(begin, complete)
  }

  const goToStep = (step) => {
    if (dev) {
      setSteps(step)
      setIsLoading(false)
      setTimeout(
        () => (document.querySelector('.loadScreen').style.display = 'none'),
        1000,
      )
      return
    }
    const complete = () => {
      setSteps('') // unset for state update - helpful to just re-init same step
      setSteps(step)
      setIsLoading(true)
      imagesLoaded('.page', () => {
        readyToFadeIn()
      })
    }

    fadeOut(complete)
  }

  useInitRoute(fetchData.realData, goToStep)

  if (!fetchData.realData) {
    return (
      <div className="loadingApp">
        <span>Loading...</span>
      </div>
    )
  }

  return (
    <Fragment>
      {isLoading ? (
        <div className="loadingApp">
          <span>Loading...</span>
        </div>
      ) : null}
      <div className="loadScreen"></div>
      <div className={`App`}>
        {steps === 'intro' ? (
          <Intro
            goToNext={() => goToStep('map')}
            introIsAnimating={introIsAnimating}
            setIntroIsAnimating={setIntroIsAnimating}
            data={fetchData.realData}
          />
        ) : null}

        {steps === 'map' ? (
          <MapPage data={fetchData.response} realData={fetchData.realData} />
        ) : null}
      </div>
      {fetchData.realData.detailPages ? (
        <MediaModal
          detailPages={fetchData.realData.detailPages.data}
          glossaryUrl={fetchData.realData.glossary_pdf}
        />
      ) : null}
    </Fragment>
  )
}

export default App
