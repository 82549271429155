import React, { useState, useEffect } from 'react'
import { GenericModal } from 'moar-interactive-shared'
import renderHTML from 'react-render-html'

import InfoBubble from './../InfoBubble'
import isMobile from '../../utils/isMobile'

const DetailMapModal = (props) => {
  const [selected, setSelected] = useState(null)
  const { detailMap, setDetailMap, other_info } = props

  const generateMedia = () => {
    return (
      <div className="detailPage__media">
        {other_info.hotspots.image ? (
          <div className="tabNavImage">
            {other_info.hotspots.items.map((tab, index) => {
              let activeClass = ''
              if (selected && tab.id === selected.id) {
                activeClass = 'is-active'
              }
              return (
                <InfoBubble
                  a11yDescription="Select hotspot"
                  key={index}
                  className={activeClass}
                  onClick={() => {
                    setSelected(tab)
                  }}
                  x={tab.x}
                  y={tab.y}
                />
              )
            })}

            <img
              alt={'navigation hotspots container'}
              src={other_info.hotspots.image}
            />
          </div>
        ) : null}
      </div>
    )
  }

  const setText = () => {
    if (selected) {
      if (selected.text) {
        return renderHTML(selected.text)
      }
    } else {
      return renderHTML(other_info.text)
    }
  }

  const mobileLayout = () => {
    return (
      <div className="detailPage detailPage--mobile">
        <div className="detailPage__col detailPage__col--main">
          <div className="detailPage__header">
            <h2>{other_info.title}</h2>
          </div>
          {generateMedia()}

          <div className="detailPage__text">
            <h2>{selected ? selected.title : ''}</h2>
            {selected && selected.icon ? (
              <img className="tabIcon" src={selected.icon} alt="icon of tab" />
            ) : null}
            {setText()}
          </div>
        </div>
      </div>
    )
  }

  const desktopLayout = () => {
    return (
      <div className="detailPage detailPage--desktop">
        <div className="detailPage__col detailPage__col--main">
          <div className="detailPage__header">
            <h2>{selected ? selected.title : other_info.title}</h2>
          </div>

          <div className="detailPage__text">
            {selected && selected.icon ? (
              <img className="tabIcon" src={selected.icon} alt="icon of tab" />
            ) : null}
            {setText()}
          </div>
        </div>
        <div className="detailPage__col">{generateMedia()}</div>
      </div>
    )
  }

  return (
    <GenericModal
      showModal={detailMap}
      overlayName="detailMapModal"
      onClose={() => {
        setDetailMap(false)
        setSelected(null)
      }}
    >
      {isMobile() ? mobileLayout() : desktopLayout()}
    </GenericModal>
  )
}

export default DetailMapModal
