import React, { useState } from 'react'
import ExplorerControls from './ExplorerControls'
import { GenericModal } from 'moar-interactive-shared'
import mediaZoomController from './../../utils/mediaZoom'

const PanZoomCpmp = (props) => {
  const { image, zoomText } = props
  const [panZoom, setPanZoom] = useState()

  const hasText = () => zoomText

  return (
    <GenericModal showModal={props.explorerOpen} overlayName={'zoomModal'}>
      <div class={`panZoomModule ${hasText() ? 'panZoomModule--info' : ''}`}>
        <div class="zoomArea__outer">
          {panZoom ? (
            <ExplorerControls
              panZoom={panZoom}
              closeExplorer={props.closeExplorer}
            />
          ) : null}

          <div className="zoomArea">
            <img
              className="zoomNode"
              alt={'zoomer'}
              src={image}
              onLoad={() => {
                const panzoomElem = document.querySelector('.zoomNode')
                const panState = new mediaZoomController(panzoomElem)
                setPanZoom(panState)
              }}
            />
          </div>
        </div>
        {zoomText ? (
          <div className="panZoomModule__info">
            <div className="panZoomModule__attr">
              <p>{zoomText}</p>
            </div>
          </div>
        ) : null}
      </div>
    </GenericModal>
  )
}

export default PanZoomCpmp
