import React, { useContext } from 'react'
import YearSelect from './YearSelect'
import AnimationContext from '../../Context/animation'
import ModalContext from '../../Context/modal'

const TimelineView = (props) => {
  const { currentData, timelineData, setDateId, dateId } = props
  const { isTutorial, isAnimating } = useContext(AnimationContext)
  const { setModalData, setShowMediaModal } = useContext(ModalContext)

  return (
    <div className="timelineView">
      {/* <button
        onClick={() => {
          setModalData(2)
          setShowMediaModal(true)
        }}
      >
        open modal
      </button> */}
      <YearSelect
        timelineData={timelineData}
        currentData={currentData}
        setDateId={setDateId}
        dateId={dateId}
      />
      {currentData.currentYear.items.map((month) => {
        return (
          <div
            key={month.id}
            className={`monthCard ${
              month.id === currentData.currentMonth.id ? 'is-active' : ''
            }`}
          >
            <button
              onClick={() => {
                if (isTutorial || isAnimating) {
                  return
                }
                setDateId(month.id)
              }}
            >
              <h3>{month.title}</h3>
              {month.description ? <p>{month.description}</p> : null}
              <span className="sr-only">Select month</span>
            </button>
          </div>
        )
      })}
    </div>
  )
}

export default TimelineView
