import anime from 'animejs/lib/anime.es.js'

const fadeOut = (hotspots, onComplete) => {
  anime({
    targets: hotspots,
    easing: 'linear',
    duration: 200,
    opacity: 0,
    complete: () => {
      if (onComplete) {
        setTimeout(() => {
          // set to block
          hotspots.forEach((spot) => {
            spot.style.display = 'none'
          })
          onComplete()
        }, 300)
      }
    },
  })
}

const fadeIn = (hotspots, onComplete) => {
  anime({
    // autoplay: false,
    targets: hotspots,
    easing: 'easeOutElastic(1, .6)',
    opacity: 1,
    translateY: [10, 0],
    delay: anime.stagger(150),
    begin: () => {
      // set to block
      hotspots.forEach((spot) => {
        spot.style.display = 'block'
      })
    },
    complete: () => {
      if (onComplete) {
        onComplete()
      }
    },
  })
}

const basicFadeOut = (hotspots, onComplete) => {
  anime({
    targets: hotspots,
    opacity: 0,
    easing: 'linear',
    duration: 300,
    complete: () => {
      hotspots.forEach((spot) => {
        spot.style.display = 'none'
      })
    },
  })
}

const basicFadeIn = (hotspots) => {
  anime({
    targets: hotspots,
    opacity: 1,
    easing: 'linear',
    duration: 300,
    begin: () => {
      hotspots.forEach((spot) => {
        spot.style.display = 'block'
      })
    },
  })
}

export { fadeIn, fadeOut, basicFadeIn, basicFadeOut }
