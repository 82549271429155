import React, { useState } from 'react'
import { ReactComponent as ChevronIcon } from './../../Icons/icon-chevron.svg'
import TimelineCompact from './TimelineCompact'
import TimelineView from './TimelineView'
import FilterView from './FilterView'
import ControlInfo from './ControlInfo'

const MapControls = (props) => {
  const {
    timelineData,
    setDateId,
    tagState,
    setTagState,
    toggleExpand,
    dateId,
    data,
  } = props

  const [view, setView] = useState('default')

  const getTitle = () => {
    if (data.display_title) {
      return data.display_title
    } else {
      return data.title
    }
  }

  return (
    <div className={`mapControls`}>
      <div className="mapControls__wrapper">
        <div className="mapControls__header">
          <h1>{getTitle()}</h1>
          <button
            className="mapControls__expandTrigger"
            onClick={() => toggleExpand()}
          >
            <ChevronIcon />
            <span className="sr-only">Minimize controls</span>
          </button>
        </div>
        <div className="mapControls__inner">
          <div className="mapControls__tabs">
            <div className="inner">
              <button
                onClick={() => setView('default')}
                className={`mapControl__tab ${
                  view === 'default' ? 'is-active' : ''
                }`}
              >
                Timeline
              </button>
              <button
                onClick={() => setView('filter')}
                className={`mapControl__tab ${
                  view === 'filter' ? 'is-active' : ''
                }`}
              >
                Filter Map
              </button>
              <div className="moreInfo">
                <ControlInfo text={data.help_text} />
              </div>
            </div>
          </div>
          <TimelineCompact
            setDateId={setDateId}
            timelineData={timelineData}
            currentData={props.currentData}
          />
          <div className="mapControls__content">
            <div className="scroller">
              {view === 'default' ? (
                <TimelineView
                  dateId={dateId}
                  setDateId={setDateId}
                  timelineData={timelineData}
                  currentData={props.currentData}
                />
              ) : null}
              {view === 'filter' ? (
                <FilterView
                  tagState={tagState}
                  setTagState={setTagState}
                  tags={data.tagGroups.data || []}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MapControls
