import React, { useState, Fragment } from 'react'
import anime from 'animejs/lib/anime.es.js'
import { IconButton } from 'moar-interactive-shared'
import PanZoom from './PanZoom'
import { ReactComponent as ExpandIcon } from './../../Icons/icon-expand.svg'

const MediaModule = (props) => {
  const { canExpand, attr, image, zoomText } = props

  const [pan, setPan] = useState(false)
  const [explorerOpen, setExplorerOpen] = useState(false)

  const openExplorer = () => {
    setPan(true)
    setExplorerOpen(true)
  }

  const closeExplorer = () => {
    setTimeout(() => {
      anime({
        easing: 'linear',
        targets: document.querySelector('.zoomModal'),
        duration: 300,
        opacity: [1, 0],
        complete: () => {
          if (canExpand) {
            setPan(false)
            setExplorerOpen(false)
          }
        },
      })
    })
  }

  return (
    <Fragment>
      <div className="media">
        <div className="media__expander">
          <div className="imageComponent">
            {canExpand ? (
              <IconButton
                icon={<ExpandIcon />}
                handleClick={() => openExplorer()}
              />
            ) : null}

            <img
              className="expander"
              alt="media pic"
              src={image}
              // src="https://via.placeholder.com/340x450.png"
            />
          </div>
        </div>
      </div>
      {attr ? <p className="media__attr">{attr}</p> : null}
      {pan ? (
        <PanZoom
          image={image}
          explorerOpen={explorerOpen}
          closeExplorer={closeExplorer}
          zoomText={zoomText}
        />
      ) : null}
    </Fragment>
  )
}

export default MediaModule
