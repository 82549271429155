import React, { forwardRef } from 'react'

const InfoBubble = forwardRef((props, ref) => {
  const { onClick, dark, onMouseEnter } = props

  return (
    <button
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
      onMouseEnter={() => {
        if (onMouseEnter) {
          onMouseEnter()
        }
      }}
      ref={ref}
      className={`infoButton ${dark ? 'infoButton--dark' : ''}`}
    >
      <span>i</span>
      <span className="sr-only">More info</span>
    </button>
  )
})

export default InfoBubble
