function userIsNotTabbing(e) {
  document.body.classList.remove('user-is-tabbing')
  window.addEventListener('keydown', userIsTabbing)
  window.removeEventListener('mousedown', userIsNotTabbing)
}

function userIsTabbing(e) {
  if (e.target.nodeName === 'INPUT' || e.target.nodeName === 'TEXTAREA') return
  document.body.classList.add('user-is-tabbing')
  window.removeEventListener('keydown', userIsTabbing)
  window.addEventListener('mousedown', userIsNotTabbing)
}

const tabCheck = () => {
  window.addEventListener('keydown', userIsTabbing)
  window.addEventListener('event:untab', userIsNotTabbing)
}

export default tabCheck
