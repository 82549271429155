import React, { useEffect, useContext, useState } from 'react'
import { GenericModal } from 'moar-interactive-shared'

import MediaModule from './../components/Media/MediaModule'
import isMobile from './../utils/isMobile'
import useWindowSize from '../hooks/useWindowSize'
import ModalContext from './../Context/modal'
import AudioPlayer from './AudioPlayer'
import InfoButton from './InfoButton'
import Tippy from '@tippyjs/react'
import { Tooltip } from 'moar-interactive-shared'
import TextModule from './TextModule'

// the media modal is a portal compoenet that is managed through modal context
// data will be set thoough modalData
const MediaModal = (props) => {
  const windowSize = useWindowSize()
  const [selectedModal, setSelectedModal] = useState(null)
  const { detailPages, glossaryUrl } = props

  const { modalData, showMediaModal, setShowMediaModal } = useContext(
    ModalContext,
  )

  useEffect(() => {
    if (!modalData) return
    const selectedDetailPage = detailPages.find((data) => data.id === modalData)
    setSelectedModal(selectedDetailPage)
  }, [modalData])

  useEffect(() => {
    // rerender on each width change
  }, [windowSize.width])

  if (!selectedModal) return null

  const tooltip = () => {
    return (
      <Tooltip bg="white">
        <p>{selectedModal.tag_label_info}</p>
      </Tooltip>
    )
  }

  if (!selectedModal) return null

  return (
    <GenericModal
      overlayName={'mediaModal'}
      showModal={showMediaModal}
      onClose={() => setShowMediaModal(false)}
    >
      <div className="detailPage">
        <div className="detailPage__col detailPage__col--main">
          <div className="detailPage__header">
            <h2>{selectedModal.title}</h2>

            <div className="modal__details">
              {selectedModal.tag_label ? (
                <div className="detailTags">
                  <span className="tag">{selectedModal.tag_label}</span>
                </div>
              ) : null}

              {selectedModal.tag_label_info ? (
                <Tippy
                  content={tooltip()}
                  placement="bottom"
                  interactive={true}
                >
                  <InfoButton />
                </Tippy>
              ) : null}
            </div>
          </div>

          {isMobile() ? (
            <div className="detailPage__media">
              <MediaModule
                canExpand={selectedModal.image_zoomable}
                image={selectedModal.image}
                attr={selectedModal.image_caption || selectedModal.image_credit}
                zoomText={selectedModal.image_zoomed_caption}
              />
            </div>
          ) : null}

          <TextModule text={selectedModal.body} glossaryUrl={glossaryUrl} />

          {selectedModal.audio ? (
            <div className="detailPage__audio">
              <AudioPlayer audioFile={selectedModal.audio} />
            </div>
          ) : null}
        </div>
        <div className="detailPage__col">
          {!isMobile() ? (
            <div className="detailPage__media">
              <MediaModule
                canExpand={selectedModal.image_zoomable}
                image={selectedModal.image}
                attr={selectedModal.image_caption || selectedModal.image_credit}
                zoomText={selectedModal.image_zoomed_caption}
              />
            </div>
          ) : null}
        </div>
      </div>
    </GenericModal>
  )
}

export default MediaModal
