import React, { Fragment } from 'react'
import { IconButton } from 'moar-interactive-shared'
import { ReactComponent as ReduceIcon } from './../../Icons/icon-reduce.svg'
import { ReactComponent as PlusIcon } from './../../Icons/icon-plus.svg'
import { ReactComponent as MinusIcon } from './../../Icons/icon-minus.svg'

const ExplorerControls = (props) => {
  const { closeExplorer } = props
  console.log(props)
  return (
    <Fragment>
      <div className="panZoomModule__close">
        <IconButton icon={<ReduceIcon />} handleClick={() => closeExplorer()} />
      </div>

      <div className="controls">
        <IconButton
          icon={<PlusIcon />}
          handleClick={() => props.panZoom.zoomIn()}
        />
        <IconButton
          icon={<MinusIcon />}
          handleClick={() => props.panZoom.zoomOut()}
        />
      </div>
    </Fragment>
  )
}
export default ExplorerControls
