import React, { useState, useContext, useRef, useEffect } from 'react'
import Tippy from '@tippyjs/react'
import InfoBubble from './../InfoBubble'
import { hideAll } from 'tippy.js'
import { Tooltip } from 'moar-interactive-shared'
import Cta from './../Cta'
import ModalContext from './../../Context/modal'
import isMobile from '../../utils/isMobile'

const Hotspot = React.forwardRef((props, ref) => {
  const { panZoom, tag, inactiveTags, index } = props
  const { setModalData, setShowMediaModal } = useContext(ModalContext)
  const [tippyInstance, setTippyInstance] = useState()

  const [isActive, setIsActive] = useState(false)

  const position = () => {
    return {
      top: `${props.y}%`,
      left: `${props.x}%`,
      zIndex: isActive ? '1' : '0',
    }
  }

  const toolTip = () => {
    return (
      <Tooltip>
        <div className={`panzoom-exclude mapSpot--${index}`}>
          <p>{props.text}</p>
          {props.cta_text ? (
            <Cta
              button={true}
              label={props.cta_text}
              icon={true}
              onBlur={() => {
                hideAll()
              }}
              handleClick={() => {
                if (tippyInstance) {
                  tippyInstance.hide()
                }
                setModalData(props.detail_page_id)
                setShowMediaModal(true)
              }}
            />
          ) : null}
        </div>
      </Tooltip>
    )
  }

  const setTag = () => {
    if (tag) {
      return `hotspot--${tag}`
    } else {
      return ''
    }
  }

  // this is a edgecase fix to release user-is-tabbing calss
  const triggerUntab = () => {
    const event = new Event('event:untab')
    window.dispatchEvent(event)
  }

  return (
    <div
      className={`hotspot ${isActive ? 'is-active' : ''} 
      ${setTag()}
      ${inactiveTags.includes(tag) ? 'is-unset' : 'is-set'}
      `}
      ref={ref}
      style={position()}
    >
      <Tippy
        sticky={true}
        placement={'bottom'}
        onHide={() => setIsActive(false)}
        appendTo={isMobile() ? document.body : 'parent'}
        onShow={(instance) => {
          setTippyInstance(instance)
          setIsActive(true)
          if (document.body.classList.contains('user-is-tabbing')) {
            panZoom.centerOn(instance.reference)
          }
        }}
        onShown={(instance) => {
          hideAll({ exclude: instance })
        }}
        interactive={true}
        content={toolTip()}
      >
        <InfoBubble
          {...props}
          onMouseEnter={() => triggerUntab()}
          a11yDescription="Open More info"
        />
      </Tippy>
    </div>
  )
})

export default Hotspot
