import React, { useState } from 'react'
import Tippy from '@tippyjs/react'
import 'tippy.js/animations/scale-subtle.css'
import InfoButton from './../InfoButton'
import { Tooltip, Button } from 'moar-interactive-shared'
import renderHTML from 'react-render-html'

const ControlInfo = (props) => {
  const { text } = props
  const [visible, setVisible] = useState(false)

  const tooltip = () => {
    return (
      <Tooltip>
        {text ? renderHTML(text) : null}

        <Button
          label="Got it"
          darkBg={true}
          handleClick={() => setVisible(false)}
        />
      </Tooltip>
    )
  }

  return (
    <div className="moreInfo">
      <Tippy
        visible={visible}
        onClickOutside={() => setVisible(false)}
        content={tooltip()}
        animation={'scale-subtle'}
        placement={'bottom'}
        interactive={true}
      >
        <InfoButton
          dark={true}
          onMouseEnter={() => setVisible(true)}
          onClick={() => setVisible(true)}
        />
      </Tippy>
    </div>
  )
}

export default ControlInfo
