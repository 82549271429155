import React, { useContext } from 'react'
import AnimationContext from './../../Context/animation'

const FilterView = (props) => {
  const { tagState, setTagState } = props
  const { isAnimating, isTutorial } = useContext(AnimationContext)

  const updateTags = (groupIndex, tagIndex) => {
    if (isAnimating || isTutorial) return
    const currentState = [...tagState]
    const currentTag = currentState[groupIndex].groupTags.data[tagIndex]
    currentTag.active = !currentTag.active
    setTagState(currentState)
  }

  const hasTitle = (tagGroup) => tagGroup.groupTags.data.length > 1

  return (
    <div className="filterView">
      {tagState.map((tagGroup, groupIndex) => {
        return (
          <div
            className={`filterView__section ${
              !hasTitle(tagGroup) ? 'filterView__section--noTitle' : ''
            }`}
            key={groupIndex}
          >
            {hasTitle(tagGroup) ? (
              <div className="filterView__sectionTitle">
                <h3>{tagGroup.title}</h3>
              </div>
            ) : null}

            {tagGroup.groupTags.data.map((tag, tagIndex) => {
              return (
                <div
                  key={tagIndex}
                  className="checkbox"
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      updateTags(groupIndex, tagIndex)
                    }
                  }}
                >
                  <input
                    onChange={() => {
                      updateTags(groupIndex, tagIndex)
                    }}
                    type="checkbox"
                    id={`check__${tag.position}`}
                    checked={tag.active}
                  />
                  <label htmlFor={`check__${tag.position}`}>
                    <span className="checkbox__label">{tag.title}</span>
                  </label>
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default FilterView
