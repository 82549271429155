import React, { useState, useEffect } from 'react'
import Map from './../components/Map/index'
import MapControls from '../components/MapControls/index'
import getCurrent from '../utils/getCurrent'
import isMobile from '../utils/isMobile'
import { closeControls, expandControls } from './../animations/mapAnimations'
import { useParams } from 'react-router-dom'
import useDateHistory from '../hooks/useDateHistory'

const MapPage = (props) => {
  const { data, realData } = props
  const timelineData = realData.navigationGroups.data
  const params = useParams()

  let selectedYear = timelineData.find((year) => year.title === params.year)

  if (!selectedYear) {
    selectedYear = timelineData[0]
  }

  let selectedMonth = selectedYear.items[0]

  if (params.month) {
    const paramMonth = selectedYear.items.find(
      (month) => month.title.toLowerCase() === params.month,
    )
    if (paramMonth) {
      selectedMonth = paramMonth
    }
  }

  // on init get first month of first year
  const [dateId, setDateId] = useState(selectedMonth.id)

  const [tagState, setTagState] = useState([])
  const [currentData, setCurrentData] = useState(
    getCurrent(dateId, timelineData),
  )
  // url updating feature
  useDateHistory(currentData)

  useEffect(() => {
    const generateTagState = () => {
      return realData.tagGroups.data.map((tagGroup) => {
        const newObj = tagGroup

        tagGroup.groupTags.data.map((tag) => {
          tag.active = true
          return tag
        })
        return newObj
      })
    }
    setTagState(generateTagState())
  }, [setTagState])

  useEffect(() => {
    setCurrentData(getCurrent(dateId, timelineData))
  }, [dateId])

  const [expand, setExpand] = useState(!isMobile())

  useEffect(() => {
    if (!expand) {
      setTimeout(() => {
        expandControls()
      })
    }
  }, [])

  const toggleExpand = () => {
    if (expand) {
      // close
      setExpand(false)
      expandControls()
    } else {
      //open
      setExpand(true)
      closeControls()
    }
  }

  return (
    <div className={`page page--main ${expand ? '' : 'mapExpand'}`}>
      <div className="page__content">
        <main>
          <MapControls
            toggleExpand={toggleExpand}
            currentData={currentData}
            dateId={dateId}
            setDateId={setDateId}
            timelineData={timelineData}
            tagState={tagState}
            setTagState={setTagState}
            data={realData}
          />
          <Map
            realData={realData}
            canZoom={false}
            expand={expand}
            setExpand={setExpand}
            currentData={currentData}
            tagState={tagState}
          />
        </main>
      </div>
    </div>
  )
}

export default MapPage
