// import axios from 'axios' // we'll use axios
import { useEffect, useState } from 'react'
import dummyData from './../data/dummyData.v2'
import axios from 'axios'

const authConfig = {
  auth: {
    username: 'moar',
    password: 'SrwCHBxxw2Fr',
  },
}

const useFetch = (interactiveUrl) => {
  const [response, setResponse] = useState({ data: [], meta: [] })
  const [realData, setRealData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      if (interactiveUrl) {
        axios
          .get(interactiveUrl, authConfig)
          .then((res) => {
            console.log(res.data.data)
            setResponse(dummyData)
            setRealData(res.data.data)
            setLoading(false)
          })
          .catch((err) => {
            setLoading(false)
            setError(err)
          })
        setResponse(dummyData)

        setLoading(false)
      } else {
        setError('No interactive url')
      }
    }
    fetchData()
  }, [interactiveUrl])
  return { response, error, loading, realData }
}

export default useFetch
