import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useInteractiveSlug from './useInteractiveSlug'

const useDateHistory = (currentData) => {
  const history = useHistory()
  const { url } = useInteractiveSlug()

  useEffect(() => {
    history.push(
      `${url}/${
        currentData.currentYear.title
      }/${currentData.currentMonth.title.toLowerCase()}`,
    )
  }, [currentData, history])
}

export default useDateHistory
