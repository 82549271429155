const getCurrent = (id, timelineData) => {
  const current = {
    currentYear: {},
    currentMonth: {},
  }

  timelineData.forEach((yearData) => {
    yearData.items.forEach((monthData) => {
      if (monthData.id === id) {
        current.currentYear = yearData
        current.currentMonth = monthData
      }
    })
  })

  return current
}

export default getCurrent
