import React, { useState, useContext, useEffect, useRef } from 'react'
import Tutorial from './Tutorial'
import panZoomController from './../../utils/panZoom'
import MapHotspots from './MapHotspots'
import AnimationContext from '../../Context/animation'
import anime from 'animejs/lib/anime.es.js'

import MapKey from './MapKey'
import Controls from './Controls'
import DetailMapModal from './DetailMap'
import isMobile from '../../utils/isMobile'
import useWindowSize from './../../hooks/useWindowSize'

const Map = (props) => {
  const { canZoom, currentData, tagState, expand, realData } = props
  const { isTutorial, setIsTutorial, setIsAnimating } = useContext(
    AnimationContext,
  )
  const windowSize = useWindowSize()
  const [mobileOverlayState, setMobileOverlayState] = useState(false)
  const [panZoom, setPanZoom] = useState()
  const [detailMap, setDetailMap] = useState(false)
  const mobileOverlay = useRef()
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    if (isMobile()) {
      if (expand) {
        setMobileOverlayState(true)
      } else {
        setMobileOverlayState(false)
      }
    } else if (mobileOverlayState) {
      setMobileOverlayState(false)
    }
  }, [windowSize, expand])

  useEffect(() => {
    if (mobileOverlay.current === null) return
    if (mobileOverlayState) {
      anime({
        targets: mobileOverlay.current,
        opacity: 1,
        begin: () => {
          mobileOverlay.current.style.display = 'block'
        },
      })
    } else {
      anime({
        targets: mobileOverlay.current,
        opacity: 0,
        complete: () => {
          if (mobileOverlay.current) {
            mobileOverlay.current.style.display = 'none'
          }
        },
      })
    }
  }, [mobileOverlayState])

  useEffect(() => {
    if (!expand) {
      // for the edgecase that the map is no
      // longer in view
      setTimeout(() => {
        if (panZoom && !isMobile()) {
          panZoom.centerPan(true)
        }
      }, 300)
    }
  }, [expand])

  if (!currentData) return null

  return (
    <div className={`mapComponent `}>
      {isTutorial ? <Tutorial setIsTutorial={setIsTutorial} /> : null}

      <div ref={mobileOverlay} className="mapOverlayMobile"></div>

      <MapKey realData={realData} />

      {realData.other_info.cta_text ? (
        <div className="mapComponent__detail">
          <button onClick={() => setDetailMap(true)}>
            <div className="mapComponent__detail-inner">
              {realData.other_info.cta_text}
            </div>
            <img alt="bg" src={realData.other_info.cta_image} />
          </button>
          <DetailMapModal
            setDetailMap={setDetailMap}
            detailMap={detailMap}
            other_info={realData.other_info}
          />
        </div>
      ) : null}

      <Controls
        canZoom={realData.zoomable}
        panZoom={panZoom}
        isTutorial={isTutorial}
      />

      <div className="mapComponent__panNode">
        {currentData.currentMonth.hotspots ? (
          <MapHotspots
            tagState={tagState}
            currentData={currentData}
            hotspots={currentData.currentMonth.hotspots.items}
            panZoom={panZoom}
            tutorialFlag={isTutorial}
            setIsAnimating={setIsAnimating}
          />
        ) : null}

        <img
          alt={'zoomer'}
          className="mapComponent__map"
          src={currentData.currentMonth.hotspots.image}
          onLoad={() => {
            if (isFirstLoad) {
              // on first load set up pan zoom
              setIsFirstLoad(false)
              const panzoomElem = document.querySelector(
                '.mapComponent__panNode',
              )
              const panZoom = new panZoomController(panzoomElem)
              setPanZoom(panZoom)
            }
          }}
        />
      </div>
    </div>
  )
}

export default Map
