import React, { useState, useEffect, useContext } from 'react'
import AnimationContext from '../../Context/animation'

const YearSelect = (props) => {
  const { timelineData, currentData, setDateId } = props
  const { isAnimating, isTutorial } = useContext(AnimationContext)
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(currentData.currentYear.title)

  useEffect(() => {
    setValue(currentData.currentYear.title)
  }, [currentData])

  useEffect(() => {
    const outsideClickListener = (event) => {
      const target = event.target
      if (!target.closest('.yearSelect')) {
        setOpen(false)
      }
    }
    if (open) {
      document.addEventListener('click', outsideClickListener)
    } else {
      document.removeEventListener('click', outsideClickListener)
    }
  }, [open])

  const selectValue = (year) => {
    setDateId(year.items[0].id)
    setValue(year.title)
    setOpen(false)
  }

  return (
    <div className="yearSelect__outer">
      <div className={`yearSelect ${open ? 'is-open' : ''}`}>
        <button
          tabIndex="0"
          className="yearSelect__value"
          onClick={() => setOpen(true)}
        >
          {value}
          <svg
            width="14"
            height="11"
            viewBox="0 0 14 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.86615 9.5406L12.9835 0.770674C13.2778 0.26636 12.6165 -0.247298 12.2006 0.162596L8.40665 3.90242C7.62783 4.67012 6.37681 4.66995 5.59822 3.90202L1.80671 0.162497C1.39098 -0.247531 0.729519 0.265992 1.02369 0.770387L6.13862 9.54042C6.52444 10.2019 7.4802 10.202 7.86615 9.5406Z"
              fill="#004899"
            />
          </svg>
          <span className="sr-only">Open Dropdown</span>
        </button>
        {open ? (
          <div className="yearSelect__list">
            {timelineData.map((year, index) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    if (isAnimating || isTutorial) return
                    selectValue(year)
                  }}
                >
                  <div>{year.title}</div>
                  <span className="sr-only">Select {year.title}</span>
                </button>
              )
            })}
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default YearSelect
