const data = {
  tagGroups: [
    {
      groupName: 'People',
      tags: [
        {
          name: 'Neutral',
          id: '232',
        },
        {
          name: 'Revolutionary',
          id: '234',
        },
        {
          name: 'Liberal',
          id: '235',
        },
      ],
    },
    {
      groupName: 'Some Group',
      tags: [
        {
          name: 'Some Tag1',
          id: '252',
        },
        {
          name: 'Some Tag2',
          id: '82',
        },
      ],
    },
    {
      groupName: 'Events',
      tags: [
        {
          name: 'Declarations',
          id: '212',
        },
      ],
    },
  ],
  data: [
    {
      year: '1776',
      months: [
        {
          id: '1',
          name: 'January',
          short: 'Jan',
          hotspots: [
            {
              customIcon: false,
              x: 30,
              y: 30,
              tag: '232',
            },
            {
              customIcon: false,
              x: 40,
              y: 40,
              tag: '234',
            },
            {
              customIcon: false,
              x: 50,
              y: 50,
              tag: '232',
            },
          ],
        },
        {
          id: '2',
          name: 'Febuary',
          short: 'Feb',
          hotspots: [
            {
              customIcon: false,
              x: 30,
              y: 30,
            },
            {
              customIcon: false,
              x: 40,
              y: 30,
            },
            {
              customIcon: true,
              x: 50,
              y: 70,
            },
            {
              customIcon: true,
              x: 29,
              y: 84,
            },
          ],
        },
        {
          id: '9',
          name: 'March',
          short: 'Mar',
          hotspots: [
            {
              customIcon: false,
              x: 30,
              y: 20,
            },
            {
              customIcon: false,
              x: 60,
              y: 30,
            },
            {
              customIcon: true,
              x: 30,
              y: 10,
            },
            {
              customIcon: true,
              x: 29,
              y: 84,
            },
          ],
        },
        {
          id: '34',
          name: 'April',
          short: 'Apr',
          hotspots: [
            {
              customIcon: false,
              x: 30,
              y: 10,
            },
            {
              customIcon: false,
              x: 60,
              y: 20,
            },
          ],
        },
        {
          id: '38',
          name: 'May',
          short: 'May',
          hotspots: [
            {
              customIcon: false,
              x: 30,
              y: 10,
            },
          ],
        },
        {
          id: '3823',
          name: 'June',
          short: 'Jun',
          hotspots: [
            {
              customIcon: false,
              x: 30,
              y: 14,
            },
          ],
        },
        {
          id: '38223',
          name: 'July',
          short: 'Jul',
          hotspots: [
            {
              customIcon: false,
              x: 31,
              y: 14,
            },
          ],
        },
      ],
    },
    {
      year: '1777',
      months: [
        {
          id: '3',
          name: 'January',
          short: 'Jan',
          hotspots: [
            {
              customIcon: false,
              x: 30,
              y: 30,
            },
            {
              customIcon: false,
              x: 60,
              y: 30,
            },
            {
              customIcon: true,
              x: 50,
              y: 50,
            },
            {
              customIcon: true,
              x: 29,
              y: 84,
            },
          ],
        },
        {
          id: '4',
          name: 'Febuary',
          short: 'Feb',
          hotspots: [
            {
              customIcon: false,
              x: 30,
              y: 30,
            },
            {
              customIcon: false,
              x: 60,
              y: 30,
            },
            {
              customIcon: true,
              x: 50,
              y: 70,
            },
            {
              customIcon: true,
              x: 29,
              y: 84,
            },
          ],
        },
      ],
    },
  ],
  details: [
    {
      id: '123',
      images: '...',
      richText: '',
      glossaryWords: {
        word: '..',
        def: '...',
      },
    },
    {
      id: '456',
      images: '...',
      richText: '',
      glossaryWords: {
        word: '..',
        def: '...',
      },
    },
  ],
}

export default data
