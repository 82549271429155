import React, { useRef, useEffect } from 'react'
import { Button } from 'moar-interactive-shared'
import { fadeIn, fadeOut } from './../animations/overlayAnimation'
import renderHTML from 'react-render-html'

const Intro = (props) => {
  const { goToNext, setIntroIsAnimating, introIsAnimating, data } = props

  const text = useRef(null)
  const button = useRef(null)

  useEffect(() => {
    setIntroIsAnimating(true)
    fadeIn(text, button, () => setIntroIsAnimating(false))
  }, [])

  const leave = () => {
    props.setIntroIsAnimating(true)
    fadeOut(text, button, () => {
      goToNext()
    })
  }

  return (
    <div className="page page--intro page--frame">
      <div className="page__wrapper">
        <div className="panel">
          <div className="panel__inner">
            <div ref={text}>
              {data.intro_text ? renderHTML(data.intro_text) : null}
            </div>

            <Button
              label={data.intro_cta_text}
              ref={button}
              handleClick={() => {
                if (introIsAnimating) {
                  return
                }
                leave()
              }}
            />
          </div>
        </div>
      </div>
      <div className="introImage">
        <img
          alt="bg"
          className="introImage__mobile"
          src={data.intro_image_mobile}
        />
        <img alt="bg" className="introImage__desktop" src={data.intro_image} />
      </div>
    </div>
  )
}

export default Intro
