import { useEffect } from 'react'
import tippy, { hideAll } from 'tippy.js'
import 'tippy.js/animations/scale-subtle.css'
import 'tippy.js/dist/tippy.css' // optional for styling

// generate html for outside of react tooltip
const generateToolTip = (def, url) => {
  return `
  <div class="tooltip infoPopup infoPopup--glossary">
    <p>${def}</p>
    ${
      url
        ? `<a rel="noopener noreferrer" target="_blank" href="${url}" class="button button--external button--light"><span>Glossary</span><svg width="14" height="14" viewBox="0 0 14 14" fill="none"><path d="M5.36764 1.97306L12.7404 0.0339112C13.481 -0.160883 14.1567 0.515015 13.9618 1.25559L12.0214 8.62681C11.8727 9.19149 11.0419 9.08688 11.0378 8.50298L11.0134 4.96817C11.0123 4.79537 10.9892 4.62777 10.9468 4.46803L1.70712 13.7081C1.31661 14.0987 0.683442 14.0987 0.292909 13.7082C-0.097624 13.3176 -0.0976381 12.6845 0.292878 12.2939L9.53557 3.05084C9.37357 3.00715 9.20341 2.98329 9.02787 2.98203L5.49123 2.9566C4.90736 2.95241 4.80296 2.12158 5.36764 1.97306Z" fill="#004899"></path></svg></a>`
        : ''
    }
    
  </div>`
}

const useGlossary = (text, ref, url) => {
  let newText = text

  useEffect(() => {
    if (ref.current) {
      // ref.current.addEventListener('glossary', () => {
      //   // go to glossary page if we have to
      // })

      const textArea = ref.current
      const wordRefs = [...textArea.querySelectorAll('.wordDefine')]

      // const appendState = () => {
      //   var ua = navigator.userAgent.toLowerCase()
      //   if (ua.indexOf('safari') != -1) {
      //     if (ua.indexOf('chrome') > -1) {
      //       return 'parent'
      //     } else {
      //       return document.body
      //     }
      //   }
      //   return 'parent'
      // }

      wordRefs.forEach((elem) => {
        tippy(elem, {
          interactive: true,
          content: 'some text',
          allowHTML: true,
          arrow: false,
          placement: 'bottom',
          animation: 'scale-subtle',
          appendTo: document.body,
          onShow(instance) {
            hideAll({ exclude: instance })
          },
          onCreate(instance) {
            instance.setContent(
              generateToolTip(elem.getAttribute('data-def'), url),
            )
          },
        })
      })
    }
  }, [])

  return newText
}

export default useGlossary
