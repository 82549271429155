import React, { useState, Fragment, useEffect } from 'react'
import Modal from 'react-modal'
import Tippy from '@tippyjs/react'
import isMobile from '../../utils/isMobile'
import { ReactComponent as CloseIcon } from './../../Icons/icon-close.svg'
import renderHTML from 'react-render-html'

const MapKey = (props) => {
  const { realData } = props
  const [keyVisible, setKeyVisible] = useState(!isMobile())
  const show = () => setKeyVisible(true)
  const hide = () => setKeyVisible(false)

  useEffect(() => {
    setTimeout(() => {
      hide()
    }, [10000])
  }, [])

  const generateKeys = (keys) => {
    return (
      <div className="keyPanel__key">
        <h3>Map Key</h3>
        <ul>
          {keys.map((key, index) => {
            const bgColor = { backgroundColor: key.color }
            return (
              <li key={index}>
                <div className="keyColor" style={bgColor}></div>
                <span>{key.title}</span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  const generatePanel = () => {
    return (
      <div className="keyPanel">
        <div className="keyPanel__content">
          <button className="keyPanel__close" onClick={hide}>
            <CloseIcon />
          </button>
          <div className="keyPanel__description">
            {renderHTML(realData.additional_info)}
          </div>
          {realData.mapKeys.data.length > 0
            ? generateKeys(realData.mapKeys.data)
            : null}
        </div>
      </div>
    )
  }

  if (isMobile()) {
    return (
      <Fragment>
        <button
          tabIndex="0"
          className="mapKeyTrigger"
          onClick={() => setKeyVisible(true)}
        >
          <svg
            width="4"
            height="19"
            viewBox="0 0 4 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.441558 6.1039H3.55844V18.5714H0.441558V6.1039ZM0 1.87013C0 1.36797 0.181818 0.935065 0.545454 0.571429C0.926407 0.190476 1.4026 0 1.97403 0C2.54545 0 3.02164 0.181818 3.4026 0.545455C3.80087 0.891775 4 1.33333 4 1.87013C4 2.40693 3.80087 2.85714 3.4026 3.22078C3.02164 3.5671 2.54545 3.74026 1.97403 3.74026C1.4026 3.74026 0.926407 3.55844 0.545454 3.19481C0.181818 2.81385 0 2.37229 0 1.87013Z"
              fill="white"
            />
          </svg>
          <span className="sr-only">Close Map Key</span>
        </button>
        <Modal
          isOpen={keyVisible}
          shouldCloseOnOverlayClick={true}
          overlayClassName="mapKeyOverlay"
        >
          {generatePanel()}
        </Modal>
      </Fragment>
    )
  } else {
    return (
      <div className="mapKey">
        <Tippy
          appendTo={document.body}
          visible={keyVisible}
          content={generatePanel()}
          animation={'scale-subtle'}
          placement={'right'}
          interactive={true}
        >
          <button
            tabIndex="0"
            onFocus={show}
            onClick={show}
            className="mapKeyTrigger"
          >
            <svg
              width="4"
              height="19"
              viewBox="0 0 4 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.441558 6.1039H3.55844V18.5714H0.441558V6.1039ZM0 1.87013C0 1.36797 0.181818 0.935065 0.545454 0.571429C0.926407 0.190476 1.4026 0 1.97403 0C2.54545 0 3.02164 0.181818 3.4026 0.545455C3.80087 0.891775 4 1.33333 4 1.87013C4 2.40693 3.80087 2.85714 3.4026 3.22078C3.02164 3.5671 2.54545 3.74026 1.97403 3.74026C1.4026 3.74026 0.926407 3.55844 0.545454 3.19481C0.181818 2.81385 0 2.37229 0 1.87013Z"
                fill="white"
              />
            </svg>
          </button>
        </Tippy>
      </div>
    )
  }
}

export default MapKey
