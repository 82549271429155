import React, { useRef } from 'react'
import renderHTML from 'react-render-html'
import useGlossary from './../hooks/useGlossary'

const TextModule = (props) => {
  const textRef = useRef()
  const { text, glossaryUrl } = props

  // merge glossary words with tippy - WARN - this is outside of react but manages via events on the textRef
  const updatedText = useGlossary(text, textRef, glossaryUrl)

  return <div ref={textRef}>{renderHTML(updatedText)}</div>
}

export default TextModule
