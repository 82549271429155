import '@babel/polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import AppRouter from './AppRouter'
import 'tippy.js/dist/tippy.css' // optional for styling
import './scss/main.scss'
import tabCheck from './utils/isUserTabbing'

tabCheck()

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`)

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
})

const isIE = () => {
  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ')

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    return true
  } else {
    return false
  }
}

if (isIE()) {
  alert(
    'The Redcoats use this browser. Upgrade your browser to join the Revolutionaries',
  )
  window.history.back()
} else {
  ReactDOM.render(<AppRouter />, document.getElementById('root'))
}
