import React, { useContext } from 'react'
import AnimationContext from '../../Context/animation'

const TimelineCompact = (props) => {
  const { timelineData, currentData, setDateId } = props

  const { isTutorial, isAnimating } = useContext(AnimationContext)

  const isCurrentYear = (year) => {
    const currentYear = currentData.currentYear.title
    if (currentYear === year) {
      return true
    } else {
      return false
    }
  }

  const isCurrentMonth = (id) => {
    const currentMonth = currentData.currentMonth.id
    if (currentMonth === id) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="timelineCompact">
      {timelineData.map((yearData, index) => {
        return (
          <section
            key={index}
            className={`yearSection ${
              isCurrentYear(yearData.title) ? 'is-current' : ''
            } `}
          >
            <h2>{yearData.title}</h2>
            {yearData.items.map((month) => {
              return (
                <button
                  key={month.id}
                  className={`dayTrigger ${
                    isCurrentMonth(month.id) ? 'is-active' : ''
                  }`}
                  onClick={() => {
                    if (isTutorial || isAnimating) {
                      return
                    }
                    setDateId(month.id)
                  }}
                >
                  {month.short_title}
                  <span className="sr-only">
                    Select {month.short_title} of {yearData.title}
                  </span>
                </button>
              )
            })}
          </section>
        )
      })}
    </div>
  )
}

export default TimelineCompact
