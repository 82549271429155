import { useEffect, useState } from 'react'

import {
  fadeIn,
  fadeOut,
  basicFadeOut,
  basicFadeIn,
} from './../animations/hotspotAnimations'
import isArrayEqual from './../utils/isArrayEqual'

const getAllHotspots = () => {
  return [...document.querySelectorAll('.mapComponent .hotspot')]
}

const getSetHotspots = () => {
  return [...document.querySelectorAll('.mapComponent .is-set')]
}

const getUnsetHotspots = () => {
  return [...document.querySelectorAll('.mapComponent .is-unset')]
}
const getInactive = (tagGroups) => {
  const inactiveArray = []
  tagGroups.forEach((tagGroup) => {
    tagGroup.groupTags.data.forEach((tag) => {
      if (!tag.active) {
        inactiveArray.push(tag.id)
      }
    })
  })

  return inactiveArray
}

const useHotspotFilter = (
  currentData,
  tagState,
  tutorialFlag,
  setIsAnimating,
) => {
  const [inactiveTags, setInactiveTags] = useState([])
  const [hotspotData, setHotspotData] = useState(
    currentData.currentMonth.hotspots.items,
  )

  //////////////////////////////////////////////////////
  //
  // on first open
  //
  //////////////////////////////////////////////////////
  useEffect(() => {
    setIsAnimating(true)
    setTimeout(() => {
      if (!tutorialFlag) {
        const hotspots = getSetHotspots()
        fadeIn(hotspots, () => setIsAnimating(false))
      }
    })
  }, [tutorialFlag])

  //////////////////////////////////////////////////////
  //
  // if data change update
  //
  //////////////////////////////////////////////////////
  useEffect(() => {
    // if data is stale animate them out!
    if (!isArrayEqual(currentData.currentMonth.hotspots.items, hotspotData)) {
      setTimeout(() => {
        if (tutorialFlag) return
        const hotspots = getAllHotspots()
        setIsAnimating(true)
        fadeOut(hotspots, () => {
          setHotspotData(currentData.currentMonth.hotspots.items)
          setTimeout(() => {
            const setSpots = getSetHotspots()
            fadeIn(setSpots, () => setIsAnimating(false))
          })
        })
      }, 0)
    }
  }, [currentData, hotspotData])

  //////////////////////////////////////////////////////
  //
  // if tags change update
  //
  //////////////////////////////////////////////////////
  useEffect(() => {
    setInactiveTags(getInactive(tagState))

    setTimeout(() => {
      if (tutorialFlag) return
      const setSpots = getSetHotspots()
      const unsetSpots = getUnsetHotspots()
      basicFadeIn(setSpots)
      basicFadeOut(unsetSpots)
    })
  }, [tagState])

  return {
    inactiveTags,
    hotspotData,
  }
}

export default useHotspotFilter
