import React, { Fragment } from 'react'
import { IconButton } from 'moar-interactive-shared'
import { ReactComponent as PlusIcon } from './../../Icons/icon-plus.svg'
import { ReactComponent as MinusIcon } from './../../Icons/icon-minus.svg'

const Controls = (props) => {
  const { panZoom, isTutorial, canZoom } = props
  return (
    <Fragment>
      {canZoom ? (
        <div className="mapComponent__controls">
          <IconButton
            icon={<PlusIcon />}
            handleClick={() => {
              if (isTutorial) {
                return
              }
              panZoom.zoomIn()
            }}
          />
          <IconButton
            icon={<MinusIcon />}
            handleClick={() => {
              if (isTutorial) {
                return
              }
              panZoom.zoomOut()
            }}
          />
        </div>
      ) : null}
    </Fragment>
  )
}

export default Controls
