import anime from 'animejs/lib/anime.es.js'

const handAnimation = () => {
  const hand = document.querySelector('.tutorialOverlay .hand')

  const timeline = anime
    .timeline({
      autoplay: false,
      easing: 'linear',
    })
    .add(
      {
        targets: hand,
        opacity: [0, 1],
      },
      0,
    )
    .add({
      targets: hand,
      duration: 1800,
      translateX: [-40, 40],
    })
    .add(
      {
        targets: hand,
        opacity: [1, 0],
      },
      1500,
    )
  //loop animation
  // auto play
  timeline.play()
  setInterval(() => {
    timeline.play()
  }, 1600)
}

const fadeOut = (onComplete) => {
  anime({
    easing: 'linear',
    targets: document.querySelector('.tutorialOverlay'),
    opacity: [1, 0],
    duration: 400,
    complete: () => {
      if (onComplete) {
        onComplete()
      }
    },
  })
}
export { handAnimation, fadeOut }
