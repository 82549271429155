import React, { useState } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import App from './App'
import ModalContext from './Context/modal'
import AnimationContext from './Context/animation'

const AppRouter = () => {
  const [modalData, setModalData] = useState(null)
  const [showMediaModal, setShowMediaModal] = useState(false)

  const [isAnimating, setIsAnimating] = useState(false)
  const [isTutorial, setIsTutorial] = useState(true)

  const slug = document.querySelector('#root').getAttribute('data-slug')

  return (
    <AnimationContext.Provider
      value={{
        isAnimating,
        setIsAnimating,
        isTutorial,
        setIsTutorial,
      }}
    >
      <ModalContext.Provider
        value={{
          modalData,
          setModalData,
          showMediaModal,
          setShowMediaModal,
        }}
      >
        <Router>
          <Switch>
            <Route exact path={`/interactives/${slug}/app/:year/:month`}>
              <App />
            </Route>
            <Route exact path={`/interactives/${slug}/app/:year`}>
              <App />
            </Route>
            <Route exact path={`/interactives/${slug}/app`}>
              <App />
            </Route>
          </Switch>
        </Router>
      </ModalContext.Provider>
    </AnimationContext.Provider>
  )
}

export default AppRouter
