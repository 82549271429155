import React, { forwardRef } from 'react'

const InfoBubble = forwardRef((props, ref) => {
  const { icon, x, y, onClick, onMouseEnter, panZoom, a11yDescription } = props

  const setPos = () => {
    if (x && y) {
      // minus half the size of the hotspot to center origin
      return {
        position: 'absolute',
        left: `calc(${x}% - 16px)`,
        top: `calc(${y}% - 16px)`,
      }
    }
  }

  const customClassNames = () => {
    if (props.className) {
      return props.className
    } else {
      return ''
    }
  }

  return (
    <button
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
      onMouseEnter={() => {
        if (onMouseEnter) {
          onMouseEnter()
        }
      }}
      style={setPos()}
      ref={ref}
      className={`infoBubble ${panZoom ? 'panzoom-exclude' : ''} ${
        icon ? 'infoBubble--custom' : ''
      } ${customClassNames()}`}
    >
      <span className="sr-only">{a11yDescription}</span>
      {icon ? <img alt="avatar" src={icon} /> : null}
    </button>
  )
})

export default InfoBubble
