import anime from 'animejs/lib/anime.es.js'
const expandControls = () => {
  const targets = [
    document.querySelector('.mapControls__content'),
    document.querySelector('.mapControls__tabs'),
    document.querySelector('.mapControls__header h1'),
  ]
  const compactTimeline = document.querySelector('.timelineCompact')
  anime({
    targets: targets,
    easing: 'linear',
    duration: 300,
    opacity: [1, 0],
  })

  anime({
    targets: compactTimeline,
    easing: 'linear',
    duration: 300,
    opacity: [0, 1],
    begin: () => {
      compactTimeline.style.display = 'block'
    },
  })
}

const closeControls = () => {
  const targets = [
    document.querySelector('.mapControls__content'),
    document.querySelector('.mapControls__tabs'),
    document.querySelector('.mapControls__header h1'),
  ]
  const compactTimeline = document.querySelector('.timelineCompact')
  anime({
    targets: targets,
    easing: 'linear',
    duration: 300,
    opacity: [0, 1],
  })
  anime({
    targets: compactTimeline,
    easing: 'linear',
    duration: 100,
    opacity: [1, 0],
    complete: () => {
      compactTimeline.style.display = 'none'
    },
  })
}

const hotspotTimeline = () => {
  const hotspots = [...document.querySelectorAll('.mapComponent .hotspot')]
  const timeline = anime({
    autoplay: false,
    targets: hotspots,
    easing: 'easeOutElastic(1, .6)',
    opacity: [0, 1],
    translateY: [40, 0],
    delay: anime.stagger(100),
  })
  return timeline
}

export { expandControls, closeControls, hotspotTimeline }
