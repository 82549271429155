import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const useInitRoute = (data, goToStep) => {
  const params = useParams()

  useEffect(() => {
    if (!data) {
      return
    }
    if (params.year) {
      goToStep('map')
    } else {
      goToStep('intro')
    }
  }, [data])
}

export default useInitRoute
